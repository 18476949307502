/* //////////////////////////////////////////////////////////////// */
/* FOOTER STYLING */
/* //////////////////////////////////////////////////////////////// */
.footer_container{
    width: 100%;
    height: 80px;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.footer_container div{
    margin: auto;
    letter-spacing: 1px;
    font-size: 12px;
    color: var(--socialsColor);
}

/* .footer_link{
    padding: 4px;
} */
.footer_link a{
    position: relative;
    bottom: 0px;
    top: 0px;
    transition: 0.3s
}
.footer_link a:hover{
    color: var(--secondaryColor);
    top: -3px;
}
@media only screen and (max-width: 768px){

    .footer_container div{
        font-size: 9px;

    }
}


/* 
.swatch_container{
    width: 100%;
    height: 70%;
    display: flex;
    background-color: blue;
}
.swatch{
    margin: auto;
    padding: auto;
    width: 260px;
    height: 50px;
    height: 90%;
    background-color: var(--blue);
    z-index: 1;
    border-radius: 15px;
    transform-origin: top left;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swatch_animated{
    animation: swatch_open 2s 
}
@keyframes swatch_open{
    0%{
        transform: rotate(0deg);
    }
    10%{
        transform: rotate(-40deg);
    }
    90%{
        transform: rotate(-40deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.hidden_swatch{
    margin-bottom: 5.5%;
    margin-left: -66%;
    background-color: rgb(210, 199, 199);
    z-index: 0;
}
.swatch p{
    margin: auto;
    letter-spacing: 0.7px;
    letter-spacing: 1px;
}
.circle{
    background-color: white;
    border-radius: 100px;
    width: 10px;
    height: 10px;
    margin-left: 20px;
} */