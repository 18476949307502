/* //////////////////////////////////////////////////////////////// */
/* GLOBAL CSS VARIABLES */
/* //////////////////////////////////////////////////////////////// */
:root{
    /* COLORS */
    --blue: #7C9AB9;
    --green: #A3A86D;
    --darkerWhite: #e3e1de;
    --brown: #c89967;
    --offWhite: #ece8e3;

    --backgroundColor: #ececec;
    --socialsColor: rgb(68, 68, 68);
    --secondaryColor: var(--blue);
    --secondaryColor2: #B78B9D;
    --secondaryColor3: rgb(210, 199, 199);

    /* CARD VARIABLES */
    --cardBackground: white;
    --cardScreen: var(--blue);
    --card_width: 350px;
    --card_height: 370px;

    /* ANIMATION TIMES */
    --loadingAnimationLength: 5s;

    --camAnimationDelay: 2.5s; 
    --camAnimationLength: 8s;
}

.header_line{
    position: relative;
    width: 90%;
    height: 30px;
    background-color: var(--blue);
    margin-left: 5%;
    margin-top: -5%;
    z-index: -1;
}
.hide{
    display: none;
}
.hide >*{
    display: none;
}


/* PAGE ANIMATIONS */
.fade_in_left{
    opacity: 0;
    transition: 0.5s;
    transform: translateX(-50px);
}
.fade_in_up{
    opacity: 0;
    transition: 0.5s;
    transform: translateY(40px);
}
.fade_in_appear{
    opacity: 1;
    transform: translateY(0);
    transform: translateX(0);
}


/* LOADING STUFF */
/* Fill animation learned from: */
/* Codingflag channel */
/* https://www.youtube.com/watch?v=wGPDuqgkoew&ab_channel=Codingflag */
.loading_container{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 12;
    background-color: var(--backgroundColor);
    animation: popout 1.5s 2.4s;
}
@keyframes popout{
    10%{
        transform: scale(1);
    }
    20%{
        transform: scale(1.25);
    }
    30%{
        transform: scale(0);
    }
    100%{
        transform: scale(0);
    }
}
.loading_box{
    width: 70px;
    height: 70px;
    border: 7px solid white;
    position: relative;
    overflow: hidden; 
}
.loading_box:before{
    content: "";
    position: absolute;
    width: 400px;
    height: 400px;
    background: var(--blue);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 40%;
    animation: fill var(--loadingAnimationLength) ease-in-out;
}
@keyframes fill{
    from {
        top: 70px;
        transform: translateX(-50%) rotate(0deg);
    }
    to {
        top: -50px;
        transform: translateX(-50%) rotate(360deg);
    }
}

/* PRELOAD IMAGES USING CSS */
/* Find better way with JS */
/* https://stackoverflow.com/questions/1373142/preloading-css-images */
.loading_box:after{
    position: absolute; width: 0; height: 0;
    /* overflow: hidden; */
    z-index: -1;
    content: 

    /* Animation pictures */
    url('../assets/camera_sequence/01.png')
    url('../assets/camera_sequence/02.png')
    url('../assets/camera_sequence/03.png')
    url('../assets/camera_sequence/04.png')
    url('../assets/camera_sequence/05.png')
    url('../assets/camera_sequence/09.png')
    url('../assets/camera_sequence/010.png')
    url('../assets/camera_sequence/011.png')
    url('../assets/camera_sequence/012.png')
    url('../assets/camera_sequence/013.png')

    /* Projects pictures */
    url('../assets/projectBackgrounds/youkaryote.jpg')
    url('../assets/projectBackgrounds/youkaryote2.jpg')
    url('../assets/projectBackgrounds/c0vm.jpg')
    url('../assets/projectBackgrounds/EDE.jpg')
    url('../assets/projectBackgrounds/construction.png')

}
.loading_box_photography:after{
  
}






.spinner{
    height: 100px;
    width: 100px;
    border: 5px solid;
    border-color: white solid white solid;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    /* background-color: red; */
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
