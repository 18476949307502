@tailwind base;
@tailwind components;
@tailwind utilities; 

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Work+Sans&display=swap'); 

@layer base{

    body {
        /* @apply bg-[#ece8e3]; */
        /* @apply bg-[#ece8e3]; */
        @apply bg-[#ececec];
        /* @apply font-[Roboto]; */
        /* @apply font-["Work Sans"]; */
        /* @apply font-[Helvetica]; */
    }
    li {
        @apply px-4;
        @apply cursor-pointer; 
    }
}
/* @layer components{

} */