/* //////////////////////////////////////////////////////////////// */
/* SOCIALS SIDE BAR */
/* //////////////////////////////////////////////////////////////// */
.socials_container{
    position: fixed;
    bottom: 0;
    left: 4vw;
    width: 30px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--socialsColor);
    z-index: 3;
    opacity: 1;
    transition: 0.9s;
}
.socials_container_hidden{
    opacity: 0;
}

.socials_container a{
    padding: 7px;
    transition: 0.3s;
}
.social_icon{
    position: relative;
    bottom: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
}
.social_icon:hover{
    color: var(--secondaryColor);
    top: -3px;
}
.socials_line{
    height: 100%;
    margin-top: 3px;
    width: 4px;
    background-color: var(--socialsColor);
    border-radius: 10px;
}
.leaves{
    position: fixed;
    bottom: 7%;
    margin-left: -5px;
    width: 180px;
    height: 110px;
    z-index: 1;
}
