.nav-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.75rem;
    background-color: rgb(237, 237, 237,0.4);
    letter-spacing: 1px;
    z-index: 10;
    transition: 0.3s;
    backdrop-filter: blur(10px);
}
.nav{
    display: flex;
    transition: 0.3s;
    margin-right: -1vw;
}
.nav-container-hidden{
    transform: translateY(-80px);
}
.logo{
    margin-left: 3vw;
    transition: 0.3s;
    /* padding-left: 10vw; */
}
.logo:hover{
    transform: scale(1.1);
}
.logo a{
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
.nav_link{
    position: relative;
}
.nav_link:after{
    content: "";
    position: absolute;
    background-color: var(--secondaryColor);
    height: 3px;
    width: 0;
    left: 0;
    bottom: 5px;
    bottom: -3px;
    transition: 0.3s; 
}
.nav_link:hover:after{
    width: 100%;
}

.active{
    font-weight: bold;
    color: var(--secondaryColor);
}

.nav_mobile{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    width: 70%;
    height: 100vh;
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: 0.3s;
    font-size: 15px;
    /* transform: translateX(-100%); */
}
.nav_mobile_hidden{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    height: 100vh;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    background-color: var(--backgroundColor);
    transition: 0.3s;
}

