.photography_container{
    background-color: var(--backgroundcolor);
    padding-top: 80px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: middle;
    justify-content: center;
}

.gallery-wrapper{
    margin: auto;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    width: 80%;
    height: 80%;
}

.gallery-border{
    position: absolute;
    width: 70%;
    height: 100%;
    padding: 0 12px;
    margin-top: 10px;
    border-width: 5px;
    border-color: #A3A86D;
    z-index: 5;
    margin-left: 2%;
    pointer-events: none;
}
.gallery-title{
    height: 50px;
    width: 100%;
}
.gallery-content{
    /* margin-left: -3%; */
    /* display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;  */
}
.box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dream{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* width: 32.5%; */
}

.gallery-box{
    position: relative;
    width: 100%;
    transition: 0.3s ease;
}

.gallery-box img{
    display: block;
    width: 100%;
    padding-bottom: 14px;
    z-index: 0;
}

.gallery-description{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    letter-spacing: 3px;
    font-weight: 100px;

    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s ease;
}

.gallery-description h3{
    font-size: 15px;
    color: rgb(213, 213, 213)
}


.gallery-box:hover{
    transform: scale(1.03);
    filter: grayscale(50%);
}

.gallery-box:hover .gallery-description{
    opacity: 1;
}


.dream img{
    display: block;
    width: 100%;
    padding-bottom: 14px;
    transition: 0.3s ease;
    z-index: 0;
}

.dream p{
    position: relative;
    top: -50%;
    left: 60%;
    transform: translate(-50%, -50%);
    /* top: 50%; */
    /* margin: auto; */
    /* top: 50%;
    left: 50%; */
    display: block;
    opacity: 0;
    font-size: 17px;
    color: white;
    letter-spacing: 3px;
    z-index: 3;
    transition: 0.3s;
}


.dream p:hover{
    opacity: 0;
}
.dream img:hover{
    transform: scale(1.03);
    filter: grayscale(30%);
}
.dream img:hover + p{
    opacity: 1;
}
.gallery-border{
    border-width: 5px;
    border-color: #A3A86D;
}