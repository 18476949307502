/* //////////////////////////////////////////////////////////////// */
/* MAIN INTRO PAGE */
/* //////////////////////////////////////////////////////////////// */
.intro_section{
    max-width: 1000px;
    height: 100vh;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.intro_title{
    width: 50%;
    height: 600px;
    margin-top: -35px;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
}
.intro_title h1{
    margin-left: 40px;
}
.intro_title h1 + h1{
    margin-left: 20px;
}


/* CARD STYLING */
.intro_card{
    width: var(--card_width);
    height: var(--card_height);

    background-color: var(--cardBackground);
    margin-left: -110px;
    margin-top: -30px;

    display: flex;
    align-items: center;
    flex-direction: column;

    /* transform: rotate(-20deg); */
    /* box-shadow: 2px 5px 5px 5px rgba(136, 136, 136, 0.5); */
}
.intro_card a{
    cursor: none;
}

.card_screen{
    background-color: var(--cardScreen);
    margin-top: 10px;
    width: 95%;
    height: 80%;
    cursor: none;
    position: relative;
}
.card_screen_invisible{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 12;
    width: 100%;
    height: 100%; 
    background-color: red;
    /* border: solid 10px rgba(0,0,0,0); */
}
.card_screen_visible{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
}

/* //////////////////////////////////////////////////////////////// */
/* CAMERA ANIMATION */
/* //////////////////////////////////////////////////////////////// */
.cam_animation{
    /* cursor: cell; */
    width: 100%;
    height: 100%;
    display: block;
    margin-top: 5%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 190% 140%;
    background-image: url('../assets/camera_sequence/01.png');
    animation: var(--camAnimationLength) camera var(--camAnimationDelay) infinite
}
@keyframes camera{
    0%{
        background-image: url('../assets/camera_sequence/01.png');
    }
    0.5%{
        background-image: url('../assets/camera_sequence/02.png');
    }
    2%{
        background-image: url('../assets/camera_sequence/03.png');
    }
    3%{
        background-image: url('../assets/camera_sequence/04.png'); 
    }
    3.5%{
        background-image: url('../assets/camera_sequence/05.png');
    }
    4.5%{
        background-image: url('../assets/camera_sequence/09.png');
    }
    7% {
        background-image: url('../assets/camera_sequence/010.png');
    }
    10% {
        background-image: url('../assets/camera_sequence/011.png');
    }
    11% {
        background-image: url('../assets/camera_sequence/012.png');
    }
    12% { 
        background-image: url('../assets/camera_sequence/013.png');
    }
    13%{
        background-image: url('../assets/camera_sequence/01.png');
    }
    100%{
        background-image: url('../assets/camera_sequence/01.png');
    }
}

/* @keyframes camera{
    0%{
        background-image: url('../assets/camera_sequence/01.png');
    }
    0.5%{
        background-image: url('../assets/camera_sequence/02.png');
    }
    2%{
        background-image: url('../assets/camera_sequence/03.png');
    }
    3%{
        background-image: url('../assets/camera_sequence/04.png'); 
    }
    3.5%{
        background-image: url('../assets/camera_sequence/05.png');
    }
    4.5%{
        background-image: url('../assets/camera_sequence/09.png');
    }
    7% {
        background-image: url('../assets/camera_sequence/010.png');
    }
    10% {
        background-image: url('../assets/camera_sequence/011.png');
    }
    11% {
        background-image: url('../assets/camera_sequence/012.png');
    }
    12% { 
        background-image: url('../assets/camera_sequence/013.png');
    }
    13%{
        background-image: url('../assets/camera_sequence/01.png');
    }
    100%{
        background-image: url('../assets/camera_sequence/01.png');
    }
} */

/* //////////////////////////////////////////////////////////////// */
/* BOUNCING SCROLL DOWN ARROW*/
/* //////////////////////////////////////////////////////////////// */
.scroll_down_container{
    position: absolute;
    top: 80vh;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}
.scroll_down{
    width: 50px;
    height: 100%;
    display: block;
    z-index: 2;
    cursor: pointer;
}
.scroll_down span{
    width: 15px;
    height: 15px;
    display: block;
    margin: auto;
    border-right: 3px solid var(--blue);
    border-bottom: 3px solid var(--blue);
    opacity: 0.6;
    transform: rotate(45deg);
    margin-top: -4px;
    animation: scroll 2s linear infinite;
}
.animate-arrow-move span:nth-child(2){
    animation-delay: -0.2s;
}
.animate-arrow-move span:nth-child(3){
    animation-delay: -0.3s;
}
@keyframes scroll{
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}
/* //////////////////////////////////////////////////////////////// */
/* CUSTOM MOBILE CSS  */
/* //////////////////////////////////////////////////////////////// */
@media only screen and (max-width: 768px){
    .intro_section{
        flex-direction: column;
        align-items: center;
        margin-top: -6vh;
    }
    .intro_title{
        align-items: center;
        height: 10%;
    }
    .intro_title h1{
        font-size: 70px;
        margin-left: 0px;
    }
    .intro_title h1 + h1{
        margin-left: 0px;
    }
    .intro_card{
        width: 320px;
        height: 350px;
        margin-left: 0px;
    }
    .scroll_down_container{
        position: absolute;
        top: 84%;
    }
}

/* //////////////////////////////////////////////////////////////// */
/* CURSOR ANIMATION */
/* //////////////////////////////////////////////////////////////// */
/* Bezier transformation: */
.cursor{
    background-color: white;
    position: absolute;
    width: 20px;
    height: 20px;
    transition: 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 
    0.1s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
    user-select: none;
    border-radius: 50%; 
    z-index: 5;
}
.cursor_fade{
    transform: scale(0)
}
.cursor_active{
    pointer-events: none;
    z-index: 20;
    transform: scale(4); 
}
.cursor::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute; 
    top: 0;
    left: 0;
    display: block;
    background-image: url('../assets/photos_thumb.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 190% 150%;
}

/* //////////////////////////////////////////////////////////////// */
/* IDEAS */
/* //////////////////////////////////////////////////////////////// */
.tape1{
    position: absolute;
    top: -8%;
    left: 96%;
    width: 30px;
    transform: rotate(120deg);
    height: 50px;
    background-color: #9f772b;
    opacity: 0.8;
    filter: drop-shadow(2px, 2px, 2px, grey);
}
.tape2{
    position: absolute;
    bottom: -9%;
    left: -5%;
    width: 30px;
    transform: rotate(120deg);
    height: 50px;
    background-color: #9f772b;
    opacity: 0.8;
    filter: drop-shadow(4px, 4px, 4px, black);
}
